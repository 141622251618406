import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Avatar,
  WrapItem,
  Text,
  AvatarGroup,
  AvatarBadge,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  Link,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { getUsername, removeUserSession } from '../auth/UserData';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';

const Navbar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(45 * 60);
  const [isLogOpen, setIsLogOpen] = useState(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      removeUserSession();
      navigate('/login');
    }
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleLogout = e => {
    setIsLogOpen(true);
  };

  const handleConfirm = e => {
    removeUserSession();
    navigate('/login');
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <Flex align="center" justifyContent="center" bg="white">
      <Flex flex="1" justify="flex-end" mr={5} cursor={'pointer'}>
        <WrapItem alignItems={'end'}>
          <Popover>
            <PopoverTrigger>
              <AvatarGroup spacing="">
                <Avatar maxW={8} maxH={8} border={'none'} bg={colors.primary}>
                  <AvatarBadge border={'none'} boxSize="0.5em" bg="green.500" />
                </Avatar>
                <Text px={2} fontSize={12} color={colors.black}>
                  {getUsername()}
                </Text>
              </AvatarGroup>
            </PopoverTrigger>
            <PopoverContent bgColor={'white'} color="black">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader justifyContent={'between'}>
                {t('sidebar.session')}: {formatTime(timeLeft)}
              </PopoverHeader>
              <PopoverBody>
                <Flex
                  justifyContent={'center'}
                  direction={'column'}
                  mr={5}
                  cursor={'pointer'}
                >
                  <Stack spacing={3}>
                    <Link onClick={handleLogout}>{t('login.logout')}</Link>

                    <Popover>
                      <PopoverTrigger>
                        <Button variant="link">
                          <Text>{t('language.changeLanguage')}</Text>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent width="200px">
                        <PopoverArrow />
                        <PopoverBody>
                          <Button
                            width="100%"
                            onClick={() => changeLanguage('en')}
                          >
                            {t('language.english')}
                          </Button>
                          <Button
                            width="100%"
                            onClick={() => changeLanguage('pt')}
                            mt={2}
                          >
                            {t('language.portuguese')}
                          </Button>
                          <Button
                            width="100%"
                            onClick={() => changeLanguage('es')}
                            mt={2}
                          >
                            {t('language.spanish')}
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Stack>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </WrapItem>

        <Modal
          isCentered
          isOpen={isLogOpen}
          onClose={() => setIsLogOpen(false)}
        >
          <ModalOverlay />
          <ModalContent background={'white'}>
            <ModalHeader>{t('modals.confirmLogout')}</ModalHeader>
            <ModalBody>{t('modals.infoSureLogout')}</ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                mx={2}
                onClick={() => setIsLogOpen(false)}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                href="/login"
                onClick={handleConfirm}
              >
                {t('buttons.confirm')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Flex>
  );
};

export default Navbar;
