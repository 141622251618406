const ibanRegexMap = {
  AUT: /^AT[0-9]{18}$/,
  BEL: /^BE[0-9]{14}$/,
  CYP: /^CY[0-9]{10}[0-9A-Z]{16}$/,
  DEU: /^DE[0-9]{20}$/,
  EST: /^EE[0-9]{18}$/,
  ESP: /^ES[0-9]{22}$/,
  FIN: /^FI[0-9]{16}$/,
  FRA: /^FR[0-9]{12}[0-9A-Z]{11}[0-9]{2}$/,
  GRC: /^GR[0-9]{9}[0-9A-Z]{16}$/,
  IRL: /^IE[0-9]{2}[A-Z]{4}[0-9]{14}$/,
  ITA: /^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/,
  LTU: /^LT[0-9]{18}$/,
  LUX: /^LU[0-9]{5}[0-9A-Z]{13}$/,
  LVA: /^LV[0-9]{2}[A-Z]{4}[0-9A-Z]{13}$/,
  MLT: /^MT[0-9]{2}[A-Z]{4}[0-9]{5}[0-9A-Z]{18}$/,
  NLD: /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/,
  PRT: /^PT[0-9]{23}$/,
  SVN: /^SI[0-9]{17}$/,
  SVK: /^SK[0-9]{22}$/,
  AND: /^AD[0-9]{22}$/,
  MCO: /^MC[0-9]{25}$/,
  SMR: /^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/,
  VAT: /^VA[0-9]{20}$/,
  BGR: /^BG[0-9]{2}[A-Z]{4}[0-9]{4}[0-9A-Z]{10}$/,
  CZE: /^CZ[0-9]{22}$/,
  DNK: /^DK[0-9]{16}$/,
  HRV: /^HR[0-9]{19}$/,
  HUN: /^HU[0-9]{26}$/,
  POL: /^PL[0-9]{10}[0-9A-Z]{16}$/,
  ROU: /^RO[0-9]{2}[A-Z]{4}[0-9A-Z]{16}$/,
  SWE: /^SE[0-9]{22}$/,
  LIE: /^LI\d{7}[0-9A-Z]{12}$/,
  ISL: /^IS[0-9]{24}$/,
  NOR: /^NO[0-9]{13}$/,
  CHE: /^CH[0-9]{19}$/,
  GBR: /^GB[0-9]{2}[A-Z]{4}[0-9]{14}$/,
  CAN: /^[0-9]{5,12}$/,
  AUS: /^[0-9]{6,9}$/,
  NZL: /^\d{2}\d{4}\d{7}(\d{3}|0\d{2})$/,
  USA: /^\d+$/,
};

const bicRegexMap = {
  AUS: /^[A-Z]{4}AU.{2}.*$/,
  CAN: /^[A-Z]{4}CA.{2}.*$/,
  NZL: /^[A-Z]{4}NZ.{2}.*$/,
  USA: /^[0-9]{9}$/,
};

export const validateIBAN = (countryISO3, iban) => {
  iban = iban.replace(/\s+/g, '');
  const regex = ibanRegexMap[countryISO3];
  if (!regex) {
    return false;
  }

  const isValid = regex.test(iban);
  return isValid;
};

export const validateBIC = (countryISO3, bic) => {
  bic = bic.replace(/\s+/g, '');
  const regex = bicRegexMap[countryISO3];
  if (!regex) {
    return false;
  }

  const isValid = regex.test(bic);
  return isValid;
};
