import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Text,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
  DrawerOverlay,
  Divider,
  Select,
  Switch,
} from '@chakra-ui/react';
import MultiSelect from '../../../../components/MultiSelect/MultiSelect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import {
  getProducts,
  getProduct,
  registerProduct,
  getProvider,
} from '../../../../redux/action/products';
import { useToast } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon, EditIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/custom/colors';
import { getMerchants } from '../../../../redux/action/merchant';

const DataProcess = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      productCode: '',
      partnerId: '',
      settlementCurrencyCode: [],
      description: '',
      provider: '',
      operationType: [],
      currentList: [],
      countryCode: [],
      fees: [],
      rules: [],
    },
  });
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [formFee, setFormFee] = useState({
    description: '',
    method: '',
    value: '',
    target: '',
    priority: '',
    visible: '',
  });
  const [formLimit, setFormLimit] = useState({
    description: '',
    method: '',
    value: '',
    bufferTime: '',
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const countryCodeField = register('countryCode', {
    required: 'Country Code is required',
  });
  const operationField = register('operationType', {
    required: 'Operation Type is required',
  });
  const currentField = register('currentList', {
    required: 'Current List is required',
  });
  const currencyCodeField = register('settlementCurrencyCode', {
    required: 'Currency Code is required',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [addNew, setaddNew] = useState(false);
  const [addFees, setAddFees] = useState([]);
  const [providers, setProviders] = useState([]);
  const [countries, setCountries] = useState([]);
  const [addLimits, setAddLimits] = useState([]);
  const [addNewFee, setaddNewFee] = useState(false);
  const [addNewLimit, setAddNewLimit] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [merchantId, setMerchantId] = useState([]);
  const deleteButtonTemplate = rowData => {
    return (
      <Flex justifyContent={'start'} ps={12}>
        <Switch id="email-alerts" onChange={() => handleEnable(rowData)} />
      </Flex>
    );
  };
  const ViewButtonTemplate = data => {
    return (
      <Flex justifyContent={'start'} ps={10}>
        <SearchIcon
          cursor={'pointer'}
          onClick={() => handleOpenDetails(data)}
        />
      </Flex>
    );
  };

  const deleteLimitAndFeeButtonTemplate = (rowData, onOpenModalDel) => {
    return (
      <DeleteIcon
        marginLeft={4}
        cursor="pointer"
        onClick={() => onOpenModalDel(rowData)}
      />
    );
  };

  useEffect(() => {
    const products = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setProducts(data);
      } catch (error) {}
    };
    products();
  }, []);

  useEffect(() => {
    const providers = async () => {
      try {
        const data = await dispatch(getProvider()).unwrap();
        setProviders(data);
      } catch (error) {}
    };
    providers();
  }, []);

  useEffect(() => {
    const merchants = async () => {
      try {
        const data = await dispatch(getMerchants()).unwrap();
        setMerchants(data);
      } catch (error) {}
    };
    merchants();
  }, []);

  const columns = [
    { field: 'productCode', header: 'Code', sortable: true },
    { field: 'providerId', header: 'Provider', sortable: false },
    { field: 'description', header: 'Description', sortable: false },
    // {
    //   field: 'iconEnable',
    //   color: 'black',
    //   header: 'Disable/Enable',
    //   sortable: false,
    //   body: rowData => deleteButtonTemplate(rowData, handleOpenModal),
    // },

    {
      field: 'iconView',
      color: 'black',
      header: 'Details',
      sortable: false,
      body: rowData => ViewButtonTemplate(rowData),
    },
  ];

  const columnsFees = [
    { field: 'description', header: 'Description', sortable: false },
    { field: 'method', header: 'Method', sortable: true },
    { field: 'value', header: 'Filter Value', sortable: false },
    { field: 'target', header: 'Target', sortable: false },
    { field: 'priority', header: 'Priority', sortable: false },
    { field: 'visible', header: 'Visible', sortable: false },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'Disable/Enable',
      sortable: false,
      body: rowData => deleteLimitAndFeeButtonTemplate(rowData, handleDelFee),
    },
  ];

  const columnsLimits = [
    { field: 'description', header: 'Description', sortable: true },
    { field: 'method', header: 'Method', sortable: true },
    { field: 'value', header: 'Value/Quantity', sortable: false },
    { field: 'bufferTime', header: 'Period(secs)', sortable: false },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'Disable/Enable',
      sortable: false,
      body: rowData => deleteLimitAndFeeButtonTemplate(rowData, handleDelLimit),
    },
  ];

  const handleDelFee = e => {
    let newFees = addFees.filter(x => x.index !== e.index);
    setAddFees(newFees);
  };

  const handleDelLimit = e => {
    let newLimits = addLimits.filter(x => x.index !== e.index);
    setAddLimits(newLimits);
  };

  async function onSubmitFee(values) {
    const newIndex = addFees.length;
    const newFee = { ...formFee, index: newIndex };
    setAddFees(prevAddFees => [...prevAddFees, newFee]);
    setFormFee({
      description: '',
      method: '',
      value: '',
      target: '',
      priority: '',
      visible: '',
    });
    setaddNewFee(false);
  }

  async function onSubmitLimit(values) {
    const newIndex = addFees.length;
    const newLimit = { ...formLimit, index: newIndex };
    setAddLimits(prevAddLimits => [...prevAddLimits, newLimit]);
    setFormLimit({
      description: '',
      method: '',
      value: '',
      target: '',
      priority: '',
      visible: '',
    });
    setAddNewLimit(false);
  }

  async function onSubmit(values) {
    values.countryCode = values.countryCode.map(x => ({
      id: x.id,
      currencyCode: x.code,
      currencyDescription: x.currencyDescription,
    }));
    values.fees = addFees;
    values.limits = addLimits;
    let data = values;
    dispatch(registerProduct(data));
    toast({
      title: t('toast.productCreateTitle'),
      position: 'top',
      description: t('toast.productCreateDesc'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    setTimeout(async () => {
      const get = await dispatch(getProducts()).unwrap();
      setProducts(get);
    }, 2000);
    setaddNew(false);
    setFormFee([]);
    setFormLimit([]);
  }

  const handleOpenDetails = async data => {
    const prod = await dispatch(getProduct(data.id)).unwrap();
    reset({
      productCode: prod.productCode,
      partnerId: prod.providerPartnerId,
      settlementCurrencyCode: prod.settlementCurrencyCode,
      description: prod.description,
      provider: prod.providerId,
      operationType: [],
      currentList: [],
      countryCode: [],
      fees: [],
      rules: [],
    });
    setMerchantId(prod.merchantId);
    setSelectedProduct(prod);
    setAddFees(prod.fees);
    setAddLimits(prod.limits);
    setIsEdit(true);
    setaddNew(true);
  };

  const handleNew = e => {
    setIsEdit(false);
    reset({
      productCode: '',
      partnerId: '',
      settlementCurrencyCode: [],
      description: '',
      provider: '',
      operationType: [],
      currentList: [],
      countryCode: [],
      fees: [],
      rules: [],
    });
    setAddFees([]);
    setAddLimits([]);
    setaddNew(true);
  };

  const handleCloseNew = e => {
    setaddNew(false);
  };

  const handleNewFee = e => {
    setaddNewFee(e);
    if (e === false) {
      setFormFee({
        description: '',
        method: '',
        value: '',
        target: '',
        priority: '',
        visible: '',
      });
    }
  };

  const handleNewLimit = e => {
    setAddNewLimit(e);
    if (e === false) {
      setFormFee({
        description: '',
        method: '',
        value: '',
        target: '',
        priority: '',
        visible: '',
      });
    }
  };

  const handleEnable = async data => {
    // console.log(data);
    // await dispatch(deleteUser(selectedAcquirer.id)).unwrap();
    // await dispatch(fetchUsers()).unwrap();
    // toast({
    //   title: 'User deleted.',
    //   position: 'top',
    //   description: 'The user has been deleted successfully.',
    //   status: 'success',
    //   duration: 4000,
    //   isClosable: true,
    // });
    // setIsDelModalOpen(false);
    // const acquirers = await dispatch(fetchUsers()).unwrap();
    // setAcquirers(acquirers);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormFee({
      ...formFee,
      [name]: value,
    });
  };

  const handleProvider = e => {
    let prov = providers.filter(f => f.id == e.target.value);
    if (prov[0].abbreviation === 'VISA') {
      setCountries([
        { code: 'AND', name: 'Andorra' },
        { code: 'AUT', name: 'Austria' },
        { code: 'BEL', name: 'Belgium' },
        { code: 'CYP', name: 'Cyprus' },
        { code: 'CZE', name: 'Czech Republic' },
        { code: 'DNK', name: 'Denmark' },
        { code: 'EST', name: 'Estonia' },
        { code: 'FIN', name: 'Finland' },
        { code: 'FRA', name: 'France' },
        { code: 'DEU', name: 'Germany' },
        { code: 'GRC', name: 'Greece' },
        { code: 'HUN', name: 'Hungary' },
        { code: 'ISL', name: 'Iceland' },
        { code: 'ITA', name: 'Italy' },
        { code: 'LVA', name: 'Latvia' },
        { code: 'LIE', name: 'Liechtenstein' },
        { code: 'LTU', name: 'Lithuania' },
        { code: 'LUX', name: 'Luxembourg' },
        { code: 'MLT', name: 'Malta' },
        { code: 'NLD', name: 'Netherlands' },
        { code: 'NOR', name: 'Norway' },
        { code: 'POL', name: 'Poland' },
        { code: 'PRT', name: 'Portugal' },
        { code: 'IRL', name: 'Republic of Ireland' },
        { code: 'ROU', name: 'Romania' },
        { code: 'SMR', name: 'San Marino' },
        { code: 'SVK', name: 'Slovakia' },
        { code: 'SVN', name: 'Slovenia' },
        { code: 'ESP', name: 'Spain' },
        { code: 'SWE', name: 'Sweden' },
        { code: 'CHE', name: 'Switzerland' },
        { code: 'GBR', name: 'United Kingdom' },
        { code: 'USA', name: 'United States of America' },
      ]);
    } else if (prov[0].abbreviation === 'MASTERCARD') {
      setCountries([
        {
          code: 'USA',
          name: 'United States',
        },
        {
          code: 'AUS',
          name: 'Australia',
        },
        {
          code: 'CAN',
          name: 'Canada',
        },
        {
          code: 'GBR',
          name: 'United Kingdom',
        },
        {
          code: 'NZL',
          name: 'New Zealand',
        },
        {
          code: 'AND',
          name: 'Andorra',
        },
        {
          code: 'AUT',
          name: 'Austria',
        },
        {
          code: 'BEL',
          name: 'Belgium',
        },
        {
          code: 'BGR',
          name: 'Bulgaria',
        },
        {
          id: 9,
          code: 'HRV',
          name: 'Croatia',
        },
        {
          id: 10,
          code: 'CYP',
          name: 'Cyprus',
        },
        {
          id: 11,
          code: 'CZE',
          name: 'Czech Republic',
        },
        {
          id: 12,
          code: 'DNK',
          name: 'Denmark',
        },
        {
          id: 13,
          code: 'EST',
          name: 'Estonia',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 14,
          code: 'FIN',
          name: 'Finland',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 15,
          code: 'FRA',
          name: 'France',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 16,
          code: 'DEU',
          name: 'Germany',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 17,
          code: 'GRC',
          name: 'Greece',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 18,
          code: 'HUN',
          name: 'Hungary',
          currencyCode: 'HUF',
          currencyDescription: 'Hungarian Forint',
        },
        {
          id: 19,
          code: 'IRL',
          name: 'Ireland',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 20,
          code: 'ISL',
          name: 'Iceland',
          currencyCode: 'ISK',
          currencyDescription: 'Icelandic Krona',
        },
        {
          id: 21,
          code: 'ITA',
          name: 'Italy',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 22,
          code: 'LVA',
          name: 'Latvia',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 23,
          code: 'LIE',
          name: 'Liechtenstein',
          currencyCode: 'CHF',
          currencyDescription: 'Swiss Franc',
        },
        {
          id: 24,
          code: 'LTU',
          name: 'Lithuania',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 25,
          code: 'LUX',
          name: 'Luxembourg',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 26,
          code: 'MLT',
          name: 'Malta',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 27,
          code: 'MCO',
          name: 'Monaco',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 28,
          code: 'NLD',
          name: 'Netherlands',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 29,
          code: 'NOR',
          name: 'Norway',
          currencyCode: 'NOK',
          currencyDescription: 'Norwegian Krone',
        },
        {
          id: 30,
          code: 'POL',
          name: 'Poland',
          currencyCode: 'PLN',
          currencyDescription: 'Polish Zloty',
        },
        {
          id: 31,
          code: 'PRT',
          name: 'Portugal',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 32,
          code: 'ROU',
          name: 'Romania',
          currencyCode: 'RON',
          currencyDescription: 'Romanian Leu',
        },
        {
          id: 33,
          code: 'SMR',
          name: 'San Marino',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 34,
          code: 'SVK',
          name: 'Slovakia',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 35,
          code: 'SVN',
          name: 'Slovenia',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 36,
          code: 'ESP',
          name: 'Spain',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
        {
          id: 37,
          code: 'SWE',
          name: 'Sweden',
          currencyCode: 'SEK',
          currencyDescription: 'Swedish Krona',
        },
        {
          id: 38,
          code: 'CHE',
          name: 'Switzerland',
          currencyCode: 'CHF',
          currencyDescription: 'Swiss Franc',
        },
        {
          id: 39,
          code: 'VAT',
          name: 'Vatican',
          currencyCode: 'EUR',
          currencyDescription: 'Euro',
        },
      ]);
    }
  };

  const handleChangeLimit = e => {
    const { name, value } = e.target;
    setFormLimit({
      ...formLimit,
      [name]: value,
    });
  };

  return (
    <>
      <Flex direction="row" alignItems="center" textAlign="center">
        <Button
          onClick={handleNew}
          variant="outline"
          boxShadow="0px 8px 15px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)"
        >
          {t('product.addNew')} +
        </Button>
        <Spacer />
      </Flex>
      <Divider my={6} borderColor={'black'} />
      <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
        <DataTable
          value={products}
          paginator
          rows={10}
          emptyMessage="no records found"
        >
          {columns.map(col => (
            <Column
              key={col.field}
              field={col.field}
              header={t(`product.${col.field}`)}
              width={col.width}
              sortable={col.sortable}
              body={col.body}
            />
          ))}
        </DataTable>
      </Box>

      <Drawer
        isOpen={addNew}
        size={'xl'}
        placement="right"
        onClose={handleCloseNew}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {!isEdit ? t('product.createNew') : t('product.details')}
          </DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isEdit && (
                <FormControl isInvalid={errors.name}>
                  <FormLabel>{t('product.product')} ID</FormLabel>
                  <Input isDisabled={isEdit} value={selectedProduct?.id} />
                </FormControl>
              )}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <FormControl isInvalid={errors.productCode}>
                  <FormLabel>{t('product.productCode')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    id="productCode"
                    placeholder={t('placeholders.productCode')}
                    {...register('productCode', {
                      required: 'Product Code required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.productCode && errors.productCode.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.description}>
                  <FormLabel>{t('product.description')}</FormLabel>
                  <Input
                    isDisabled={isEdit}
                    id="description"
                    placeholder={t('placeholders.description')}
                    {...register('description', {
                      required: 'Description required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <FormControl isInvalid={errors.provider}>
                  <FormLabel>{t('product.provider')}</FormLabel>
                  {!isEdit && (
                    <Select
                      isDisabled={isEdit}
                      id="provider"
                      placeholder={t('placeholders.option')}
                      {...register('provider', {
                        required: 'Provider is required',
                      })}
                      onChange={handleProvider}
                    >
                      {providers
                        ? providers.map(pv => {
                            return <option value={pv.id}>{pv.name} </option>;
                          })
                        : ''}
                    </Select>
                  )}

                  {isEdit && (
                    <Input
                      isDisabled={isEdit}
                      id="provider"
                      {...register('provider', {
                        required: 'Provider is required',
                      })}
                    />
                  )}
                  <FormErrorMessage>
                    {errors.provider && errors.provider.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.merchantId}>
                  <FormLabel>Merchant</FormLabel>
                  {!isEdit && (
                    <Select
                      isDisabled={isEdit}
                      id="merchantId"
                      placeholder={t('placeholders.option')}
                      {...register('merchantId')}
                    >
                      {merchants
                        ? merchants.map(mc => {
                            return <option value={mc.id}>{mc.name} </option>;
                          })
                        : ''}
                    </Select>
                  )}

                  {isEdit && (
                    <Input
                      isDisabled={isEdit}
                      value={merchantId}
                      id="merchantId"
                    />
                  )}
                  <FormErrorMessage>
                    {errors.merchantId && errors.merchantId.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.partnerId}>
                  <FormLabel>Partner Id</FormLabel>
                  <Input
                    id="partnerId"
                    isDisabled={isEdit}
                    placeholder={t('placeholders.partnerId')}
                    {...register('partnerId', {
                      required: 'PartnerId required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.partnerId && errors.partnerId.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.settlementCurrencyCode}>
                  <FormLabel>{t('product.settlementCurrency')}</FormLabel>
                  {!isEdit && (
                    <MultiSelect
                      isDisabled={isEdit}
                      id="settlementCurrencyCode"
                      label={t('product.settlementCurrency')}
                      value={watch('settlementCurrencyCode')}
                      onChange={values => {
                        setValue('settlementCurrencyCode', values, {
                          shouldValidate: true,
                        });
                      }}
                      onBlur={currencyCodeField.onBlur}
                      options={[{ name: 'USD' }, { name: 'EUR' }]}
                    />
                  )}
                  {isEdit && (
                    <Input
                      isDisabled={isEdit}
                      {...register('settlementCurrencyCode', {
                        required: 'Currency Code required',
                      })}
                    />
                  )}
                  <FormErrorMessage>
                    {errors.settlementCurrencyCode &&
                      errors.settlementCurrencyCode.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.countryCode}>
                  <FormLabel>{t('product.countryCode')}</FormLabel>
                  <MultiSelect
                    id="countryCode"
                    label={t('product.countryCode')}
                    options={countries}
                    value={watch('countryCode')}
                    onChange={values => {
                      setValue('countryCode', values, { shouldValidate: true });
                    }}
                    onBlur={countryCodeField.onBlur}
                  />
                  <FormErrorMessage>
                    {errors.countryCode && errors.countryCode.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.operationType}>
                  <FormLabel>{t('product.operationType')}</FormLabel>
                  <MultiSelect
                    isDisabled={isEdit}
                    id="operationType"
                    placeholder="Choose"
                    label={t('product.operationType')}
                    value={watch('operationType')}
                    onChange={values => {
                      setValue('operationType', values, {
                        shouldValidate: true,
                      });
                    }}
                    onBlur={operationField.onBlur}
                    options={[{ name: 'CrossBorder' }, { name: 'Domestic' }]}
                  />
                  <FormErrorMessage>
                    {errors.operationType && errors.operationType.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>

              <Flex templateColumns="repeat(1, 1fr)" gap={6} mt={8}>
                <FormControl isInvalid={errors.currentList}>
                  <FormLabel>{t('product.currentList')}</FormLabel>
                  <MultiSelect
                    isDisabled={isEdit}
                    id="currentList"
                    placeholder="Choose"
                    label={t('product.currentList')}
                    value={watch('currentList')}
                    onChange={values => {
                      setValue('currentList', values, { shouldValidate: true });
                    }}
                    onBlur={currentField.onBlur}
                    options={[
                      { name: 'AUD' },
                      { name: 'CAD' },
                      { name: 'GBP' },
                      { name: 'NZD' },
                      { name: 'EUR' },
                    ]}
                  />
                  <FormErrorMessage>
                    {errors.currentList && errors.currentList.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex mt={6} direction={'column'}>
                <Text>{t('product.productFees')}</Text>
                <Divider borderColor={'black'} my={3} />
                {!isEdit && (
                  <Flex direction={'row'} justifyContent={'end'}>
                    <Button
                      m={2}
                      onClick={() => handleNewFee(true)}
                      variant="outline"
                      boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                      my={6}
                    >
                      {t('product.newFees')} +
                    </Button>
                  </Flex>
                )}

                <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
                  <DataTable
                    value={addFees}
                    paginator
                    rows={10}
                    emptyMessage="no records found"
                  >
                    {columnsFees.map(col => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={t(`fees.${col.field}`)}
                        width={col.width}
                        sortable={col.sortable}
                        body={col.body}
                      />
                    ))}
                  </DataTable>
                </Box>
              </Flex>

              <Flex mt={6} direction={'column'}>
                <Text>{t('product.productLimits')}</Text>
                <Divider borderColor={'black'} my={3} />
                {!isEdit && (
                  <Flex direction={'row'} justifyContent={'end'}>
                    <Button
                      onClick={() => handleNewLimit(true)}
                      m={2}
                      variant="outline"
                      boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                      my={6}
                    >
                      {t('limits.addNew')} +
                    </Button>
                  </Flex>
                )}
                <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
                  <DataTable
                    value={addLimits}
                    paginator
                    rows={10}
                    emptyMessage="no records found"
                  >
                    {columnsLimits.map(col => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={t(`limits.${col.field}`)}
                        width={col.width}
                        sortable={col.sortable}
                        body={col.body}
                      />
                    ))}
                  </DataTable>
                </Box>
              </Flex>
              <DrawerFooter>
                <Button
                  cursor="pointer"
                  bgColor={'white'}
                  color={colors.primary}
                  onClick={handleCloseNew}
                  fontSize={15}
                  mx={2}
                  boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                >
                  {t('buttons.cancel')}
                </Button>
                {!isEdit && (
                  <Button isLoading={isSubmitting} type="submit">
                    {t('buttons.confirm')}
                  </Button>
                )}
              </DrawerFooter>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={addNewFee}
        size={'lg'}
        placement="right"
        onClose={() => handleNewFee(false)}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {t('fees.createNew')}
          </DrawerHeader>

          <DrawerBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
              <FormControl isInvalid={errors.description}>
                <FormLabel>{t('fees.description')}</FormLabel>
                <Input
                  isDisabled={isEdit}
                  name="description"
                  placeholder={t('placeholders.description')}
                  value={formFee.description}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl isInvalid={errors.method}>
                <FormLabel>{t('fees.method')}</FormLabel>
                <Select
                  isDisabled={isEdit}
                  name="method"
                  value={formFee.method}
                  onChange={handleChange}
                  placeholder={t('placeholders.option')}
                >
                  <option value="SUM">{t('fees.SUM')}</option>
                  <option value="PERCENTAGE">{t('fees.PERCENTAGE')}</option>
                </Select>
              </FormControl>
            </Grid>

            <Divider borderColor={'black'} my={4} />

            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <FormControl isInvalid={errors.value}>
                <FormLabel>{t('fees.value')}</FormLabel>
                <Input
                  isDisabled={isEdit}
                  name="value"
                  placeholder={t('placeholders.value')}
                  value={formFee.value}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl isInvalid={errors.target}>
                <FormLabel>{t('fees.target')}</FormLabel>
                <Select
                  isDisabled={isEdit}
                  name="target"
                  value={formFee.target}
                  onChange={handleChange}
                  placeholder={t('placeholders.option')}
                >
                  <option value={'TOTAL'}>{t('fees.TOTAL')}</option>
                  <option value={'EXCHANGE'}>{t('fees.EXCHANGE')}</option>
                </Select>
              </FormControl>
            </Grid>

            <Divider borderColor={'black'} my={4} />

            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <FormControl isInvalid={errors.priority}>
                <FormLabel>{t('fees.priority')}</FormLabel>
                <Input
                  isDisabled={isEdit}
                  name="priority"
                  placeholder={t('placeholders.priority')}
                  value={formFee.priority}
                  onChange={handleChange}
                />
                <FormErrorMessage>
                  {errors.priority && errors.priority.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.visible}>
                <FormLabel>{t('fees.visible')}</FormLabel>
                <Select
                  isDisabled={isEdit}
                  name="visible"
                  value={formFee.visible}
                  onChange={handleChange}
                  placeholder={t('placeholders.option')}
                >
                  <option value={'true'}>{t('fees.true')}</option>
                  <option value={'false'}>{t('fees.false')}</option>
                </Select>
              </FormControl>
            </Grid>
            <Flex mt={5} direction={'row'} justifyContent={'end'}>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                onClick={() => handleNewFee(false)}
                fontSize={15}
                mx={3}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
              >
                {t('buttons.cancel')}
              </Button>
              <Button isLoading={isSubmitting} onClick={onSubmitFee}>
                {t('buttons.confirm')}
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={addNewLimit}
        size={'lg'}
        placement="right"
        onClose={() => handleNewLimit(false)}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {t('limits.createNew')}
          </DrawerHeader>

          <DrawerBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
              <FormControl isInvalid={errors.description}>
                <FormLabel>{t('limits.description')}</FormLabel>
                <Input
                  isDisabled={isEdit}
                  name="description"
                  placeholder={t('placeholders.description')}
                  value={formLimit.description}
                  onChange={handleChangeLimit}
                />
                <FormErrorMessage>
                  {errors.description && errors.description.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.method}>
                <FormLabel>{t('limits.method')}</FormLabel>
                <Select
                  name="method"
                  value={formLimit.method}
                  onChange={handleChangeLimit}
                  placeholder={t('placeholders.option')}
                  isDisabled={isEdit}
                >
                  <option value={'MIN_AMOUNT_PER_TRANSACTION'}>
                    {t('limits.MIN_AMOUNT_PER_TRANSACTION')}
                  </option>
                  <option value={'MAX_AMOUNT_PER_TRANSACTION'}>
                    {t('limits.MAX_AMOUNT_PER_TRANSACTION')}
                  </option>
                  <option value={'MAX_QUANTITY_PER_SENDER'}>
                    {t('limits.MAX_QUANTITY_PER_SENDER')}
                  </option>
                  <option value={'MAX_AMOUNT_PER_SENDER'}>
                    {t('limits.MAX_AMOUNT_PER_SENDER')}
                  </option>
                  <option value={'MAX_QUANTITY_PER_RECIPIENT'}>
                    {t('limits.MAX_QUANTITY_PER_RECIPIENT')}
                  </option>
                  <option value={'MAX_AMOUNT_PER_RECIPIENT'}>
                    {t('limits.MAX_AMOUNT_PER_RECIPIENT')}
                  </option>
                </Select>
              </FormControl>
            </Grid>

            <Divider borderColor={'black'} my={4} />

            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <FormControl isInvalid={errors.value}>
                <FormLabel>{t('limits.value')}</FormLabel>
                <Input
                  name="value"
                  placeholder={t('placeholders.value')}
                  value={formLimit.value}
                  isDisabled={isEdit}
                  onChange={handleChangeLimit}
                />
              </FormControl>

              <FormControl isInvalid={errors.timestamp}>
                <FormLabel>{t('limits.bufferTime')}</FormLabel>
                <Input
                  isDisabled={isEdit}
                  name="bufferTime"
                  value={formLimit.bufferTime}
                  onChange={handleChangeLimit}
                  placeholder={t('placeholders.bufferTime')}
                />
              </FormControl>
            </Grid>
            <Flex mt={5} direction={'row'} justifyContent={'end'}>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                onClick={() => handleNewLimit(false)}
                fontSize={15}
                mx={3}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
              >
                {t('buttons.cancel')}
              </Button>
              <Button mx={2} isLoading={isSubmitting} onClick={onSubmitLimit}>
                {t('buttons.confirm')}
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const Product = () => {
  useEffect(() => {}, []);
  return (
    <>
      <Flex ms={3} flexDirection="column" px={8}>
        <Breadcrumb
          borderRadius={8}
          bg="white"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          p={2}
          color="#333"
          my={3}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Acquirer</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Products</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box bgColor={'white'} p={6} boxShadow={'md'} height={'80vh'}>
          <Flex direction="column" pt={{ base: '120px', md: '5px' }}>
            <TableContainer>
              <DataProcess />
            </TableContainer>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
