import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Text,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  StackDivider,
  Drawer,
  DrawerContent,
  useDisclosure,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  Divider,
  Switch,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import {
  fetchUsers,
  deleteUser,
  registerUser,
  getUser,
} from '../../../redux/action/users';
import { getAcquirer, getAcquirers } from '../../../redux/action/acquirer';
import { useToast } from '@chakra-ui/react';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';
import { getAcquirerId, getProfle } from '../../../components/auth/UserData';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getMerchants } from '../../../redux/action/merchant';

const DataProcess = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      acquirer: '',
      description: '',
      email: '',
    },
  });
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [acquirers, setAcquirers] = useState('');
  const [selectedAcquirer, setSelectedAcquirer] = useState('');
  const [merchants, setMerchants] = useState('');
  const [selectedMerchant, setSelectedMerchant] = useState('');

  useEffect(() => {
    const acquirers = async () => {
      try {
        let data = await dispatch(getAcquirers()).unwrap();
        setAcquirers(data);
      } catch (error) {}
    };
    acquirers();
  }, []);

  useEffect(() => {
    const merchants = async () => {
      try {
        let data = await dispatch(getMerchants()).unwrap();
        setMerchants(data);
      } catch (error) {}
    };
    merchants();
  }, []);

  const handleChangeAcquirer = e => {
    let acq = acquirers.find(acquirer => acquirer.id === e.target.value);
    setSelectedAcquirer(acq);
  };

  const handleChangeMerchant = e => {
    if (e.target.value) {
      let merchant = merchants.find(m => m.id === e.target.value);
      setSelectedMerchant(merchant);
    } else {
      setSelectedMerchant('');
    }
  };

  const EditButtonTemplate = (rowData, onOpenModal) => {
    return (
      <EditIcon
        marginLeft={8}
        cursor={'pointer'}
        // onClick={() => handleView(rowData)}
      />
    );
  };

  const ViewButtonTemplate = rowData => {
    return (
      <SearchIcon
        marginLeft={8}
        cursor={'pointer'}
        onClick={() => handleView(rowData)}
      />
    );
  };

  const EnableButtonTemplate = rowData => {
    return <Switch id="email-alerts" onChange={() => handleEnable(rowData)} />;
  };

  //   useEffect(() => {
  //     const projects = async () => {
  //       try {
  //         let data = await dispatch(fetchUsers()).unwrap();
  //         if (getProfle() === 'ACQUIRER') {
  //           data = data.filter(x => x.profile !== 'ADMIN');
  //         }
  //         setProjects(data);
  //         onClose();
  //       } catch (error) {}
  //     };
  //     projects();
  //   }, []);

  const columns = [
    { field: 'acquirer', header: 'Acquirer', sortable: true },
    { field: 'provider', header: 'Provider', sortable: true },
    { field: 'type', header: 'type', sortable: true },
    {
      field: 'iconEnable',
      color: 'black',
      header: 'Enable/Disable',
      sortable: false,
      body: rowData => EnableButtonTemplate(rowData),
    },
    {
      field: 'iconEdit',
      color: 'black',
      header: 'Edit',
      sortable: false,
      body: EditButtonTemplate,
    },

    {
      field: 'iconView',
      color: 'black',
      header: 'Details',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];

  async function onSubmit(values) {
    let req = await dispatch(
      registerUser({
        profile: selectedMerchant.id ? 'MERCHANT' : 'ACQUIRER',
        description: values.description,
        email: values.email,
        merchantId: selectedMerchant.id ? selectedMerchant.id : '',
        acquirerId: selectedAcquirer.id ? selectedAcquirer.id : '',
        secret: '',
      })
    ).unwrap();
    if (req.status == 200) {
      toast({
        title: t('toast.userCreateTitle'),
        position: 'top',
        description: t('toast.userCreateDesc'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } else if (
      req.status == 400 &&
      req.response.data.errors[0].code == 'PUR-001'
    ) {
      toast({
        title: t('toast.userCreateError'),
        position: 'top',
        description: t('errors.PUR-001'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
    onClose();
    setSelectedAcquirer('');
    setSelectedMerchant('');
    setTimeout(async () => {
      let data = await dispatch(fetchUsers()).unwrap();
      if (getProfle() === 'ACQUIRER') {
        data = data.filter(x => x.profile !== 'ADMIN');
      }
      setProjects(data);
    }, 2000);
  }

  const handleOpenModal = user => {
    setSelectedUser(user);
    setIsDelModalOpen(true);
  };

  const handleEnable = async data => {
    // console.log(data);
    // await dispatch(deleteUser(selectedAcquirer.id)).unwrap();
    // await dispatch(fetchUsers()).unwrap();
    // toast({
    //   title: 'User deleted.',
    //   position: 'top',
    //   description: 'The user has been deleted successfully.',
    //   status: 'success',
    //   duration: 4000,
    //   isClosable: true,
    // });
    // setIsDelModalOpen(false);
    // const acquirers = await dispatch(fetchUsers()).unwrap();
    // setAcquirers(acquirers);
  };

  const handleOnOpen = async e => {
    if (getProfle() === 'ACQUIRER') {
      const acquirer = await dispatch(getAcquirer(getAcquirerId())).unwrap();
      setSelectedAcquirer(acquirer);
    } else {
      setSelectedAcquirer('');
    }
    reset({
      description: '',
      email: '',
    });
    onOpen();
  };

  const handleDelete = async data => {
    await dispatch(deleteUser(selectedUser.id)).unwrap();
    await dispatch(fetchUsers()).unwrap();
    toast({
      title: t('toast.userDeleteTitle'),
      position: 'top',
      description: t('toast.userDeleteDesc'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    setIsDelModalOpen(false);
    let user = await dispatch(fetchUsers()).unwrap();
    if (getProfle() === 'ACQUIRER') {
      user = user.filter(x => x.profile !== 'ADMIN');
    }
    setProjects(user);
  };

  const handleView = async data => {
    let user = await dispatch(getUser(data.id)).unwrap();
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const handleClose = async data => {
    let user = await dispatch(fetchUsers()).unwrap();
    if (getProfle() === 'ACQUIRER') {
      user = user.filter(x => x.profile !== 'ADMIN');
    }
    setProjects(user);
    setChangePassword(false);
    setIsViewModalOpen(false);
  };

  const handleModalClose = async () => {};

  return (
    <>
      <Flex direction="column" alignItems="start" textAlign="center">
        <Button
          my={2}
          onClick={handleOnOpen}
          variant="outline"
          boxShadow="0px 8px 15px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)"
        >
          {t('projects.addNew')} +
        </Button>

        <Divider my={8} borderColor={'black'} />

        <Drawer
          placement="right"
          isOpen={isOpen}
          onClose={handleModalClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size={'xl'}
        >
          <DrawerContent overflowY="auto" backgroundColor={'white'}>
            <DrawerHeader color={'black'} borderBottomWidth="1px">
              {t('projects.createNew')}
            </DrawerHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box m={3}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.acquirer}>
                    <FormLabel>Acquirer:</FormLabel>
                    <Select
                      {...register('acquirer', {
                        required: 'Acquirer required',
                      })}
                      onChange={handleChangeAcquirer}
                      placeholder={t('placeholders.option')}
                      id="acquirer"
                    >
                      {acquirers &&
                        acquirers.map(op => {
                          return (
                            <option key={op.id} value={op.id}>
                              {op.name}
                            </option>
                          );
                        })}
                    </Select>
                    <FormErrorMessage>
                      {errors.acquirer && errors.acquirer.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />
              </Box>
              <Box p={3} justifyContent={'end'} display={'flex'}>
                <Button
                  variant="outline"
                  border={'1px solid #982549'}
                  color={'#982549'}
                  onClick={onClose}
                  mx={2}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  isDisabled={!selectedAcquirer}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('buttons.confirm')}
                </Button>
              </Box>
            </form>
          </DrawerContent>
        </Drawer>
        <Spacer />
      </Flex>

      <Box bgColor={'#eff7f7'} p={6} borderRadius={8}>
        <DataTable
          value={projects}
          paginator
          rows={10}
          emptyMessage="Nenhum registro encontrado"
        >
          {columns.map(col => (
            <Column
              key={col.field}
              field={col.field}
              header={t(`projects.${col.field}`)}
              width={col.width}
              sortable={col.sortable}
              body={col.body}
            />
          ))}
        </DataTable>
      </Box>

      <Drawer
        isOpen={isViewModalOpen}
        onClose={() => handleClose(false)}
        size={'md'}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader>{t('projects.details')}</DrawerHeader>

          <DrawerBody>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t('projects.name')}</Text>
                <Input
                  isDisabled={true}
                  value={selectedUser?.description}
                  variant="filled"
                  placeholder="Filled"
                />
              </Box>
              <Box>
                <Text>Email</Text>
                <Input
                  value={selectedUser?.email}
                  isDisabled={true}
                  variant="filled"
                  placeholder="Filled"
                />
              </Box>

              {selectedUser?.profile !== 'ADMIN' && (
                <Box>
                  <Text>ACQUIRER</Text>
                  <Input
                    value={selectedUser?.acquirerName}
                    isDisabled={true}
                    variant="filled"
                    placeholder="Filled"
                  />
                </Box>
              )}

              {selectedUser?.profile == 'MERCHANT' && (
                <Box>
                  <Text>MERCHANT</Text>
                  <Input
                    value={selectedUser?.merchantName}
                    isDisabled={true}
                    variant="filled"
                    placeholder="Filled"
                  />
                </Box>
              )}
              <Box>
                <Text>{t('projects.profile')}</Text>
                <Input
                  value={selectedUser?.profile}
                  isDisabled={true}
                  variant="filled"
                  placeholder="Filled"
                />
              </Box>
              <Box>
                <Text>{t('projects.date')}</Text>
                <Input
                  value={moment(selectedUser?.date).format('DD/MM/YYYY')}
                  isDisabled={true}
                  variant="filled"
                  placeholder="active"
                />
              </Box>

              {changePassword && (
                <Box>
                  <Text>Password</Text>
                  <Input variant="filled" placeholder="Password" mb={5} />

                  <Text>Confirm Password</Text>
                  <Input variant="filled" placeholder="Confirm Password" />
                </Box>
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            {selectedUser && (
              <Button
                variant="outline"
                colorScheme={'#982549'}
                mx={3}
                onClick={() => handleClose(false)}
              >
                {t('buttons.cancel')}
              </Button>
            )}
            {!selectedUser && (
              <Button onClick={() => handleClose(false)}>
                {t('buttons.confirm')}
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal
        isCentered
        isOpen={isDelModalOpen}
        onClose={() => setIsDelModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent background={'white'}>
          <ModalHeader> {t('modals.confirmDeletion')}</ModalHeader>
          <ModalBody>{t('projects.infoSure')}</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsDelModalOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button mx={2} colorScheme="blue" mr={3} onClick={handleDelete}>
              {t('buttons.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const Projects = () => {
  return (
    <>
      <Flex ms={2} flexDirection="column" px={8}>
        <Breadcrumb
          borderRadius={8}
          bg="white"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          p={2}
          color="#333"
          my={3}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Muevy</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Projects</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box bgColor={'white'} p={6} boxShadow={'md'} minH={'80vh'}>
          <Flex direction="column" pt={{ base: '120px', md: '5px' }}>
            <TableContainer>
              <DataProcess />
            </TableContainer>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
