import { getToken, removeUserSession } from '../../components/auth/UserData';
import baseUrl from '../../config/config';
import axios from 'axios';

export const api = axios.create({ baseURL: baseUrl });

api.interceptors.request.use(config => {
  config.headers.Authorization = getToken();
  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      removeUserSession();
      window.location.href = '/login';
    } else {
      return error;
    }
  }
);
