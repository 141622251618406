import React, { useState } from 'react';
import {
  Image,
  Flex,
  Box,
  Card,
  Text,
  Stack,
  CardBody,
  Input,
  InputGroup,
  Divider,
  Button,
  VStack,
  StackDivider,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { forgotPassword, resetPassword } from '../../redux/action/users';
import { CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';
import ReCAPTCHA from 'react-google-recaptcha';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(false);
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [validEmail, setValidEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userAuth, setUserAuth] = useState(false);
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const handleClick = () => setShow(!show);
  const handleClickTwo = () => setShowTwo(!showTwo);

  const handleRequest = async data => {
    if (!userAuth) {
      const resp = await dispatch(
        forgotPassword({
          acquirerId: 'ACQUIRER_ID',
          merchantId: 'MERCHANT_ID',
          userId: email,
        })
      ).unwrap();
      if (resp.status === 200) {
        toast({
          title: t(`toast.newPassword`),
          position: 'top',
          description: t(`toast.emailSend`),
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        handleAuth(true);
      } else {
        toast({
          title: t(`toast.newPassword`),
          position: 'top',
          description: t(`toast.userNotFound`),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } else {
      if (password === confirmPassword) {
        const resp = await dispatch(
          resetPassword({
            userId: email,
            otpToken: token,
            newSecret: password,
            confirmNewSecret: confirmPassword,
          })
        ).unwrap();
        if (resp?.status !== 200 || !resp.status) {
          toast({
            title: t(`toast.newPassword`),
            position: 'top',
            description: t(`toast.resetError`),
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        } else {
          navigate('/login')
        }
      } else {
        toast({
          title: t(`toast.newPassword`),
          position: 'top',
          description: t(`toast.passwordSame`),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const blurEmail = async e => {
    let valid = await validateEmail(e);
    if (!valid) {
      toast({
        title: t(`toast.newPassword`),
        position: 'top',
        description: t(`toast.invalidEmail`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } else {
    }
  };
  const handleAuth = e => {
    setUserAuth(e);
  };

  const handlePasswordChange = e => setPassword(e.target.value);

  const handleTokenChange = e => setToken(e.target.value);

  const handleConfirmPasswordChange = e => setConfirmPassword(e.target.value);

  const validatePassword = password => ({
    reqOne: password.length >= 9 && password.length <= 64,
    reqTwo: /[A-Z]/.test(password),
    reqFour: /\d/.test(password),
    reqFive: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  });

  const passwordValidation = validatePassword(password);
  const isPasswordValid = Object.values(passwordValidation).every(req => req);

  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const validateEmail = async e => {
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setValidEmail(true);
      setEmail(e.target.value);
      return true;
    } else {
      setValidEmail(false);
      return false;
    }
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <Flex
        height="97vh"
        direction="row"
        justifyContent={'center'}
        alignItems="center"
        textAlign="center"
      >
        <Card
          maxW="sm"
          boxShadow="4px 4px 12px rgba(0, 0, 0, 0.1)"
          borderRadius={20}
        >
          <CardBody>
            <Flex justifyContent={'center'} direction={'column'} mx={5}>
              <Image mx={5} maxHeight={50} src="/img/logo-muevy.svg" />
              <Text as={'b'} my={3} fontSize="sm" color={colors.primary}>
                {t('login.resetPassword')}
              </Text>
            </Flex>
            {!userAuth && (
              <>
                <Stack my="6" spacing="3">
                  <Text fontSize={14} color={'black'}>
                    {t('login.descForget')}
                  </Text>
                  <InputGroup my={3}>
                    <Input
                      // type="email"
                      onChange={handleEmail}
                      value={email}
                      onBlur={blurEmail}
                      borderColor={'black'}
                      _placeholder={{ color: 'black' }}
                      placeholder="E-mail"
                      color={'black'}
                    />
                  </InputGroup>
                </Stack>
                <Flex justifyContent={'center'}>
                  <ReCAPTCHA
                    size="compact"
                    sitekey="6LfAAxsqAAAAAKswXBTMTMOyRTHF6fWG7RFMR5xC"
                    onChange={value => setCaptchaValue(value)}
                  />
                </Flex>
              </>
            )}

            {userAuth && (
              <VStack
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
              >
                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    Token
                  </Text>
                  <Input
                    value={token}
                    onChange={handleTokenChange}
                    borderColor={'black'}
                    _placeholder={{ color: 'black' }}
                    color={'black'}
                    placeholder={t('placeholders.token')}
                  />
                </Flex>

                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    {t('login.password')}
                  </Text>
                  <InputGroup size="md">
                    <Input
                      borderColor={'black'}
                      _placeholder={{ color: 'black' }}
                      color={'black'}
                      placeholder={t('login.password')}
                      type={show ? 'text' : 'password'}
                      value={password}
                      onChange={handlePasswordChange}
                    />

                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? (
                          <ViewOffIcon></ViewOffIcon>
                        ) : (
                          <ViewIcon></ViewIcon>
                        )}
                      </Button>
                    </InputRightElement>
                    {mismatch && (
                      <Text fontSize="xs" as={'b'} color={'red'}>
                        {t('createPassword.mismatch')}
                      </Text>
                    )}
                  </InputGroup>

                  <Flex
                    mt={2}
                    justifyContent={'start'}
                    alignItems={'start'}
                    direction={'column'}
                  >
                    <Flex my={1} alignItems="center">
                      {passwordValidation.reqOne ? (
                        <CheckCircleIcon boxSize={4} color="#982549" />
                      ) : (
                        <InfoOutlineIcon boxSize={4} color="gray" />
                      )}
                      <Text fontSize="xs" color="#982549" ml={2}>
                        {t('createPassword.reqOne')}
                      </Text>
                    </Flex>
                    <Flex my={1} alignItems="center">
                      {passwordValidation.reqTwo ? (
                        <CheckCircleIcon boxSize={4} color="#982549" />
                      ) : (
                        <InfoOutlineIcon boxSize={4} color="gray" />
                      )}
                      <Text fontSize="xs" color="#982549" ml={2}>
                        {t('createPassword.reqTwo')}
                      </Text>
                    </Flex>
                    <Flex my={1} alignItems="center">
                      {passwordValidation.reqFour ? (
                        <CheckCircleIcon boxSize={4} color="#982549" />
                      ) : (
                        <InfoOutlineIcon boxSize={4} color="gray" />
                      )}
                      <Text fontSize="xs" color="#982549" ml={2}>
                        {t('createPassword.reqFour')}
                      </Text>
                    </Flex>
                    <Flex my={1} alignItems="center">
                      {passwordValidation.reqFive ? (
                        <CheckCircleIcon boxSize={4} color="#982549" />
                      ) : (
                        <InfoOutlineIcon boxSize={4} color="gray" />
                      )}
                      <Text fontSize="xs" color="#982549" ml={2}>
                        {t('createPassword.reqFive')}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    {t('login.confirmPassword')}
                  </Text>
                  <InputGroup size="md">
                    <Input
                      type={showTwo ? 'text' : 'password'}
                      value={confirmPassword}
                      isDisabled={!isPasswordValid}
                      onChange={handleConfirmPasswordChange}
                      borderColor={'black'}
                      _placeholder={{ color: 'black' }}
                      color={'black'}
                      placeholder={t('login.confirmPassword')}
                    />

                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClickTwo}>
                        {showTwo ? (
                          <ViewOffIcon></ViewOffIcon>
                        ) : (
                          <ViewIcon></ViewIcon>
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              </VStack>
            )}
          </CardBody>
          <Divider />
          <Flex
            direction="column"
            justifyContent={'center'}
            alignItems="center"
            textAlign="center"
            bgColor={'white'}
          >
            <Button
              cursor="pointer"
              fontSize={12}
              m={5}
              px={10}
              isDisabled={!validEmail}
              onClick={handleRequest}
            >
              {userAuth ? t('buttons.confirm') : t('buttons.continue')}
            </Button>

            <Button
              bgColor={'white'}
              color={colors.primary}
              _hover={{ bg: colors.primary, color: 'white', boxShadow: 'md' }}
              m={2}
              fontSize={12}
              cursor={'pointer'}
              onClick={() => navigate('/login')}
            >
              {t('buttons.goBack')}
            </Button>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};
