import React, { useState, useEffect } from 'react';
import {
  Image,
  Flex,
  Box,
  Card,
  Text,
  CardBody,
  Input,
  Divider,
  Button,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { createPassword } from '../../redux/action/users';
import { useDispatch } from 'react-redux';
import { CheckCircleIcon, CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';
import ReCAPTCHA from 'react-google-recaptcha';

export const CreatePassword = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hash = params.get('hash');
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(false);
  const [token, setToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (hash) {
      setToken(true);
    }
  }, [hash]);

  const handleRequest = async data => {
    setLoading(true);
    if (password !== confirmPassword) {
      toast({
        title: t(`toast.password`),
        position: 'top',
        description: t(`toast.passwordDontMatch`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      setLoading(false);
    } else {
      const resp = dispatch(
        createPassword({
          hashEmail: hash,
          newSecret: password,
          confirmNewSecret: confirmPassword,
        })
      );
      toast({
        title: t(`toast.password`),
        position: 'top',
        description: t(`toast.passwordCreated`),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      navigate('/login');
    }
  };

  const handlePasswordChange = e => setPassword(e.target.value);

  const handleTokenChange = e => setToken(e.target.value);

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setMismatch(true);
    } else {
      setMismatch(false);
    }
  };
  const validatePassword = password => ({
    reqOne: password.length >= 9 && password.length <= 64,
    reqTwo: /[A-Z]/.test(password),
    reqFour: /\d/.test(password),
    reqFive: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  });

  const passwordValidation = validatePassword(password);
  const isPasswordValid = Object.values(passwordValidation).every(req => req);

  return (
    <Flex
      height="93vh"
      p={12}
      direction="row"
      justifyContent={'center'}
      alignItems="center"
      textAlign="center"
    >
      {token && (
        <Card>
          <CardBody
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            minW={'30vw'}
            border={'1px 1px 1px 1px solid #982549'}
          >
            <Flex justifyContent={'center'} direction={'column'}>
              <Image maxHeight={50} src="/img/logo-muevy.svg" />
              <Divider my={3}></Divider>
              <Text fontSize="xl" my={2} as={'u'} color={'black'}>
                {t('createPassword.accountPassword')}
              </Text>
            </Flex>

            <Flex
              alignContent={'center'}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'column'}
            >
              <Flex
                justifyContent={'start'}
                alignItems={'start'}
                direction={'column'}
                my={3}
              >
                <Text fontSize="sm" as={'b'} color={'black'}>
                  {t('login.password')}
                </Text>
                <Input
                  borderColor="black"
                  _placeholder={{ color: 'black' }}
                  color="black"
                  placeholder={t('placeholders.password')}
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />

                <Flex
                  mt={2}
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                >
                  <Flex my={1} alignItems="center">
                    {passwordValidation.reqOne ? (
                      <CheckCircleIcon color="#982549" />
                    ) : (
                      <InfoOutlineIcon color="gray" />
                    )}
                    <Text fontSize="xs" color="#982549" ml={2}>
                      {t('createPassword.reqOne')}
                    </Text>
                  </Flex>
                  <Flex my={1} alignItems="center">
                    {passwordValidation.reqTwo ? (
                      <CheckCircleIcon color="#982549" />
                    ) : (
                      <InfoOutlineIcon color="gray" />
                    )}
                    <Text fontSize="xs" color="#982549" ml={2}>
                      {t('createPassword.reqTwo')}
                    </Text>
                  </Flex>
                  <Flex my={1} alignItems="center">
                    {passwordValidation.reqFour ? (
                      <CheckCircleIcon color="#982549" />
                    ) : (
                      <InfoOutlineIcon color="gray" />
                    )}
                    <Text fontSize="xs" color="#982549" ml={2}>
                      {t('createPassword.reqFour')}
                    </Text>
                  </Flex>
                  <Flex my={1} alignItems="center">
                    {passwordValidation.reqFive ? (
                      <CheckCircleIcon color="#982549" />
                    ) : (
                      <InfoOutlineIcon color="gray" />
                    )}
                    <Text fontSize="xs" color="#982549" ml={2}>
                      {t('createPassword.reqFive')}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                justifyContent={'start'}
                alignItems={'start'}
                direction={'column'}
                my={3}
              >
                <Text fontSize="sm" as={'b'} color={'black'}>
                  {t('login.confirmPassword')}
                </Text>
                <Input
                  type="password"
                  isDisabled={!isPasswordValid}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  borderColor={'black'}
                  _placeholder={{ color: 'black' }}
                  color={'black'}
                  placeholder={t('placeholders.confirmPassword')}
                />
                {mismatch && (
                  <Text fontSize="xs" as={'b'} color={'red'}>
                    {t('createPassword.mismatch')}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex justifyContent={'center'} mb={3}>
              <ReCAPTCHA
                size="normal"
                sitekey="6LfAAxsqAAAAAKswXBTMTMOyRTHF6fWG7RFMR5xC"
                onChange={value => setCaptchaValue(value)}
                isDisabled={!mismatch}
              />
            </Flex>
            <Divider></Divider>
          </CardBody>
          <Flex
            direction="row"
            justifyContent={'center'}
            alignItems="center"
            textAlign="center"
            bgColor={'white'}
          >
            <Button
              bgColor={'white'}
              color={colors.primary}
              _hover={{ bg: colors.primary, color: 'white', boxShadow: 'md' }}
              m={2}
              fontSize={12}
              cursor={'pointer'}
              onClick={() => navigate('/login')}
            >
              {t('buttons.goBack')}
            </Button>
            <Button
              isDisabled={!captchaValue}
              cursor="pointer"
              fontSize={12}
              m={5}
              onClick={handleRequest}
              isLoading={loading}
            >
              {t('createPassword.createNew')}
            </Button>
          </Flex>
        </Card>
      )}

      {!token && (
        <Flex
          direction="column"
          pt={{ base: '120px', md: '25px' }}
          justifyContent="center"
          alignItems="center"
          height={'90vh'}
          p={5}
          mt={10}
        >
          <Flex
            direction="column"
            bgColor={'white'}
            border={'1px solid'}
            borderColor={'#7e7477'}
            justifyContent="center"
            alignItems="center"
            p={32}
            borderRadius={10}
          >
            <Text color={'#7e7477'} fontSize="md" my={5}>
              {t('createPassword.token')}
              <Divider borderColor={'#7e7477'} />
            </Text>
            <CloseIcon boxSize={16} m={6} color="red.500" />
            <Box my={6}>
              <Button onClick={() => navigate('/login')} mx={3}>
                {t('buttons.goBack')}
              </Button>
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
