import React, { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  List,
  ListItem,
  Text,
  SimpleGrid,
  SkeletonText,
  Box,
} from '@chakra-ui/react';
import { Card, CardBody } from '../../components/Card/CardComponent';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../redux/action/dashboard';
import DataProcess from './DataProcess';
import { Link } from 'react-router-dom';
import { getUsername, getProfle } from '../../components/auth/UserData';

export const Dashboard = () => {
  const { t } = useTranslation();
  const [totalTransactions, setTotalTransactions] = useState({});
  const [checkTotalAmount, setCheckTotalAmount] = useState(false);
  const [totalAmount, setTotalAmount] = useState({});
  const [loading, setLoading] = useState(true);

  const handleTotalTransactions = data => {
    setLoading(true);
    const statusTotalAmount =
      data?.total_amount_by_currency_code &&
      Object.keys(data.total_amount_by_currency_code).length > 0;
    setTotalTransactions(data);
    setCheckTotalAmount(statusTotalAmount);
    setTotalAmount(data.total_amount_by_currency_code || {});
    setLoading(false);
  };

  return (
    <Flex flexDirection="column" px={10} mb={10}>
      {getProfle() === 'ADMIN' ? (
        <Breadcrumb
          p={2}
          bg="white"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          color="#333"
          borderRadius={8}
        >
          <BreadcrumbItem>
            <Text fontSize="20px">
              {t(`dashboard.greeting`)}, {getUsername()}
            </Text>
          </BreadcrumbItem>
        </Breadcrumb>
      ) : (
        <>
          <Breadcrumb
            p={2}
            bg="white"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            color="#333"
            borderRadius={8}
          >
            <BreadcrumbItem>
              <BreadcrumbLink to="/Home">
                <Text fontSize={15}>Dashboard /</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px" mt={2}>
            {[
              {
                label: 'qtdTransactions',
                quantity: totalTransactions.total_quantity_transactions || 0,
                color: '#1ea9ab',
                borderColor: '#1ea9ab',
              },
              {
                label: 'approvedTransactions',
                quantity: totalTransactions.total_approved_transaction || 0,
                color: 'seagreen',
                borderColor: '#1eab4d',
              },
              {
                label: 'rejectedTransactions',
                quantity: totalTransactions.total_rejected_transactions || 0,
                color: '#ab1e1e',
                borderColor: '#ab1e1e',
              },
              {
                label: 'amountTransactions',
                color: 'Navy',
                borderColor: '#2c3333',
                amount_by_currency_code: checkTotalAmount
                  ? totalAmount
                  : { '': '0' },
              },
            ].map((card, index) => (
              <Card
                key={index}
                overflow="hidden"
                variant="outline"
                borderRadius="lg"
                bg="white"
                borderLeft={`4px solid ${card.borderColor}`}
                borderRight="1px solid white"
                borderTop="1px solid white"
                borderBottom="1px solid white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                p={4}
                mb={4}
              >
                <CardBody>
                  <Stat>
                    <StatLabel>
                      <Text fontSize="lg">{t(`dashboard.${card.label}`)}</Text>
                    </StatLabel>
                    <StatNumber color={card.color}>
                      {loading ? (
                        <Box>
                          <SkeletonText
                            mt="3"
                            noOfLines={1}
                            skeletonHeight="3"
                          />
                        </Box>
                      ) : (
                        <List spacing={3}>
                          <ListItem fontSize={40}>{card.quantity}</ListItem>
                          {Object.entries(
                            card.amount_by_currency_code || {}
                          ).map(([currencyCode, amount]) =>
                            currencyCode && amount ? (
                              <ListItem key={currencyCode} fontSize={20}>
                                <Text mb={2}>
                                  <Link to="#">{currencyCode}</Link>{' '}
                                  {formatCurrency(amount.total, currencyCode)}
                                </Text>
                              </ListItem>
                            ) : null
                          )}
                        </List>
                      )}
                    </StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
          <Flex direction="column" pt={{ base: '120px', md: '25px' }}>
            <DataProcess totalTransactionsCallback={handleTotalTransactions} />
          </Flex>
        </>
      )}
    </Flex>
  );
};
