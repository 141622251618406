import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Flex,
  Box,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useToast,
  useDisclosure,
  Stack,
  VStack,
  StackDivider,
  Divider,
  Icon,
  Grid,
  GridItem,
  PinInputField,
  PinInput,
  HStack,
} from '@chakra-ui/react';
import { ViewIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  approveRequest,
  getRequestById,
  getRequests,
  quoteUnitary,
} from '../../../redux/action/oct';
import { useTranslation } from 'react-i18next';
import { getProfle, getUserId } from '../../../components/auth/UserData';
import { otp } from '../../../redux/action/platform';
import moment, { min } from 'moment';
import colors from '../../../assets/custom/colors';

export const Requests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const [batchesQueue, setBatchesQueue] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [quote, setQuote] = useState('');
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSecondOpen,
    onOpen: onSecondOpen,
    onClose: onSecondClose,
  } = useDisclosure();
  const [onOpenQuote, setOpenQuote] = useState(false);
  const ViewButtonTemplate = rowData => {
    return (
      <Box width={'10px'}>
        <ViewIcon
          marginLeft={4}
          cursor={'pointer'}
          onClick={() => handleDetail(rowData)}
        />
      </Box>
    );
  };
  const senderFullNameTemplate = rowData => {
    return `${rowData.senderName} ${rowData.senderLastName}`;
  };
  const recipientFullNameTemplate = rowData => {
    return `${rowData.recipientName} ${rowData.recipientLastName}`;
  };
  const columns = [
    {
      field: 'senderName',
      header: 'senderName',
      sortable: true,
      body: senderFullNameTemplate,
    },
    { field: 'paymentType', header: 'paymentType', sortable: true },
    {
      field: 'recipientName',
      header: 'recipientName',
      sortable: true,
      body: recipientFullNameTemplate,
    },
    { field: 'amount', header: 'amount', sortable: true },
    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];
  const formattedAmount = (amount, currency) => {
    if (quote !== '') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: currency,
      }).format(parseFloat(amount));
    }
  };

  useEffect(() => {
    const requests = async () => {
      try {
        let data = await dispatch(getRequests()).unwrap();
        data.map(m => (m.amount = m.amount.toFixed(2)));
        setBatchesQueue(data);
      } catch (error) {}
    };
    requests();
  }, []);

  const handleDetail = async e => {
    const data = await dispatch(getRequestById(e.id)).unwrap();
    data.timestamp !== ''
      ? (data.timestamp = moment(data.timestamp).format('DD/MM/YYYY'))
      : (data.timestamp = '----');
    setSelectedTransaction(data);
    onOpen();
  };

  const handleConfirmQuote = async e => {
    let data = {
      requestOctId: selectedTransaction.id,
      quoteId: quote.quoteId,
      otpToken: pin,
    };
    let req = await dispatch(approveRequest(data)).unwrap();
    if (req?.status == 200) {
      toast({
        title: t(`toast.approveReq`),
        position: 'top',
        description: t(`toast.approveReqDesc`),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      const data = await dispatch(getRequests()).unwrap();
      setBatchesQueue(data);
    }
    setOpenQuote(false);
    onClose();
    onSecondClose();
    setQuote('');
  };

  const handleOtp = async e => {
    let data = {
      userId: getUserId(),
      transaction: 'APPROVE_OCT',
    };
    dispatch(otp(data));
    onSecondOpen();
  };

  const handleCloseQuote = async e => {
    setLoading(false);
    setOpenQuote(false);
  };

  const handleConfirm = async e => {
    setLoading(true);
    let data = {
      productId: selectedTransaction.productId,
      senderAccount: 'ewallet:474709;sp=ibcorretora',
      recipientAccount: selectedTransaction.recipientAccountNumber,
      sourceCurrencyCode: selectedTransaction.currency,
      paymentType: selectedTransaction.paymentType,
      destinationAmount: selectedTransaction.amount,
      destinationCountryCode: selectedTransaction.recipientCountryCode,
      destinationCurrencyCode: selectedTransaction.recipientCurrencyCode,
    };
    const q = await dispatch(quoteUnitary(data)).unwrap();
    if (q == undefined) {
      toast({
        title: t(`toast.quoteError`),
        position: 'top',
        description: t(`toast.quoteErrorDesc`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      setLoading(false);
    } else {
      setQuote(q);
      setOpenQuote(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Box justifyContent={'center'} ps={10} pt={3}>
        <Flex
          justifyContent="center"
          alignContent={'center'}
          display={'flex'}
          direction="column"
          textAlign="start"
        >
          <Box my={3}>
            <Text mb={8} as={'u'} fontSize={'2xl'}>
              {t('requestsOct.requestsQueue')}
            </Text>
            <DataTable
              value={batchesQueue}
              paginator
              rows={10}
              emptyMessage="Nenhum registro encontrado"
            >
              {columns.map(col => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={t(`dashboard.${col.header}`)}
                  width={col.width}
                  sortable={col.sortable}
                  body={col.body}
                />
              ))}
            </DataTable>
          </Box>
        </Flex>

        <Modal size="5xl" isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent background={'white'} p={5}>
            <Text fontSize="xl"> {t('octBatch.transactionDetails')}</Text>
            <Grid templateColumns="repeat(3, 1fr)" mt={5} gap={4}>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  Acquirer Id:{' '}
                </Text>
                <Text fontSize="xs">
                  {selectedTransaction?.acquirerId
                    ? selectedTransaction.acquirerId
                    : ''}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('dashboard.productId')}:{' '}
                </Text>
                <Text fontSize="xs">
                  {selectedTransaction?.productId
                    ? selectedTransaction.productId
                    : ''}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  Merchant Id:{' '}
                </Text>
                <Text fontSize="xs">
                  {selectedTransaction?.merchantId
                    ? selectedTransaction.merchantId
                    : ''}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="start"
                display="flex"
                flexDirection="row"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('dashboard.amount')}:{' '}
                </Text>
                <Text fontSize="sm">
                  {selectedTransaction?.amount
                    ? selectedTransaction.amount.toFixed(2)
                    : ''}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="start"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.paymentType')}:
                </Text>{' '}
                <Text fontSize="sm">{selectedTransaction?.paymentType}</Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="start"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.senderAccountNumber')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.senderAccountNumber}
                </Text>
              </Box>

              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
                <Text mt={3} fontSize="xl">
                  {' '}
                  {t('octUnitary.formUnitary.senderDetails')}
                </Text>
              </GridItem>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.firstName')}:
                </Text>

                <Text fontSize="sm">{selectedTransaction?.senderName}</Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.lastName')}:
                </Text>

                <Text fontSize="sm">{selectedTransaction?.senderLastName}</Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAddressLine')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction.senderAddressLine}
                </Text>
              </Box>

              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.senderAddressCountry')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.senderAddressCountry}
                </Text>
              </Box>

              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
                <Text mt={3} fontSize="xl">
                  {' '}
                  {t('octUnitary.formUnitary.recipientDetails')}
                </Text>
              </GridItem>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientFirstName')}:
                </Text>

                <Text fontSize="sm">{selectedTransaction?.recipientName}</Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientLastName')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientLastName}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.bankCode')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientBankCode}
                </Text>
              </Box>
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAccountNumber')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientAccountNumber}
                </Text>
              </Box>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientCountry')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientAddressCountry}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientState')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientAddressState}
                </Text>
              </Box>

              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientCity')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientAddressCity}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientPostalCode')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientAddressPostalCode}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAddressLine')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipientAddressLine}
                </Text>
              </Box>
            </Grid>

            <GridItem mt={5} colSpan={3} borderColor="#dae3e9">
              <Divider />
            </GridItem>

            <ModalFooter>
              <Flex justifyContent={'end'}>
                <Button
                  border={'1px solid'}
                  borderColor={'#7e7477'}
                  onClick={onClose}
                  mx={2}
                  variant="ghost"
                >
                  {t('buttons.cancel')}
                </Button>
                {selectedBatch.status !== 'PROCESSED' &&
                  getProfle() === 'ACQUIRER' && (
                    <Button
                      colorScheme="blue"
                      isLoading={loading}
                      onClick={handleConfirm}
                    >
                      {t('buttons.confirm')} {t('labels.quote')}
                    </Button>
                  )}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isCentered isOpen={isSecondOpen} onClose={onSecondClose}>
          <ModalOverlay />
          <ModalContent bgColor="white">
            <ModalHeader>
              <Text> {t('otp.confirmTransaction')}</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text> {t('otp.sureRequest')}</Text>

              <Flex
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                my={8}
              >
                <HStack>
                  <PinInput value={pin} onChange={value => setPin(value)}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <Text mt={5} color={'#7e7477'} fontSize={'sm'}>
                  {t('otp.otpSend')}
                </Text>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                m={3}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                onClick={onSecondClose}
              >
                {t('buttons.confirm')}
              </Button>
              <Button isDisabled={!pin} onClick={handleConfirmQuote}>
                {t('buttons.confirm')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isCentered isOpen={onOpenQuote} onClose={handleCloseQuote}>
          <ModalOverlay />
          <ModalContent bgColor="white">
            <ModalHeader>
              <Text> {t('labels.quote')}</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                my={8}
              >
                <Stack direction="column" spacing={4} align="center">
                  <Box
                    width="20vw"
                    minH={'10vh'}
                    color="#f7f5f5"
                    border={'1px solid #dae3e9'}
                    borderRadius="md"
                    borderColor={''}
                    display="flex"
                    alignItems="center"
                    boxShadow="lg"
                    mx={5}
                  >
                    <Flex
                      direction={'column'}
                      width={'100%'}
                      alignItems="start"
                    >
                      <Text color={'#7e7477'} p={3} fontSize={'sm'}>
                        {t('quote.amountSend')}
                      </Text>
                      <Divider borderColor={'gray.300'} />

                      <Box
                        display="flex"
                        width={'100%'}
                        justifyContent="space-between"
                        alignItems={'center'}
                      >
                        <Text p={3} color={'black'} fontSize={'2xl'}>
                          {quote !== '' &&
                            formattedAmount(
                              quote?.destinationAmount,
                              quote?.settlementCurrencyCode
                            )}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>

                  <Box
                    borderRadius={20}
                    bg={'#982549'}
                    display={'flex'}
                    alignContent={'center'}
                  >
                    <Icon
                      color={'white'}
                      m={1}
                      as={ArrowDownIcon}
                      w={4}
                      h={4}
                    />
                  </Box>

                  <Box
                    width="20vw"
                    minH={'10vh'}
                    borderColor={'gray.400'}
                    border={'1px solid #dae3e9'}
                    color="#f7f5f5"
                    borderRadius="md"
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                    boxShadow="lg"
                  >
                    <Flex
                      direction={'column'}
                      width={'100%'}
                      alignItems="start"
                    >
                      <Text color={'#7e7477'} p={3} fontSize={'sm'}>
                        {t('quote.amountPaid')}
                      </Text>
                      <Divider borderColor={'gray.300'} />
                      <Box
                        display="flex"
                        width={'100%'}
                        justifyContent="space-between"
                        alignItems={'center'}
                      >
                        <Text p={3} color={'black'} fontSize={'2xl'}>
                          {quote &&
                            formattedAmount(
                              quote?.settlementAmount,
                              quote?.destinationCurrencyCode
                            )}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>

                  <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <VStack
                      divider={<StackDivider borderColor="gray.200" />}
                      spacing={4}
                      align="stretch"
                    >
                      <Box
                        width={'20vw'}
                        display={'flex'}
                        justifyContent={'space-between'}
                      >
                        <Text>
                          Fx Rate <Icon />
                        </Text>
                        <Text>{quote?.fxRate}</Text>
                      </Box>
                    </VStack>
                  </Box>
                </Stack>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                m={3}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                onClick={handleCloseQuote}
              >
                {t('buttons.cancel')}
              </Button>
              <Button onClick={handleOtp}>{t('buttons.confirm')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};
