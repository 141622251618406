import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';
import { getAcquirerId, getProfle } from '../../components/auth/UserData';

export const getProducts = createAsyncThunk('products/get', async () => {
  let response;
  if (getProfle() === 'ACQUIRER') {
    response = await api.get(`/portalapi/product/acquirer`);
  } else {
    response = await api.get(`/portalapi/product/merchant`);
  }
  return response.data;
});

export const getProduct = createAsyncThunk('products/getId', async id => {
  const response = await api.get(`/portalapi/product/${id}`);
  return response.data;
});

export const getProvider = createAsyncThunk('products/provider', async id => {
  const response = await api.get(`/portalapi/provider`);
  return response.data;
});

export const getProviderById = createAsyncThunk(
  'products/provider/id',
  async id => {
    const response = await api.get(`/portalapi/provider/${id}`);
    return response.data;
  }
);

export const registerProduct = createAsyncThunk(
  'products/register',
  async data => {
    try {
      data.settlementCurrencyCode = data.settlementCurrencyCode.map(
        item => item.name
      );
      data = {
        productCode: data.productCode,
        acquirerId: getAcquirerId(),
        merchantId: data.merchantId,
        description: data.description,
        providerId: data.provider,
        providerPartnerId: data.partnerId,
        fees: data.fees,
        limits: data.limits,
        settlementCurrencies: data.settlementCurrencyCode,
        destinationCountry: data.countryCode,
      };
      const response = await api.post(`/portalapi/product`, data);
      return response.data;
    } catch (error) {}
  }
);
