import React, { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Box,
  Input,
} from '@chakra-ui/react';

const MultiSelect = ({ label, options, buttonProps, value, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Menu closeOnSelect={false}>
      {({ onClose }) => (
        <>
          <MenuButton
            minW={'100%'}
            type="button"
            backgroundColor={'white'}
            color={'gray.600'}
            borderColor={'gray.300'}
            borderWidth={1}
            p={2}
            px={4}
            borderRadius="5px"
            _focus={{
              outline: 'none',
            }}
            {...buttonProps}
          >
            {`${label}${
              selectedOptions.length > 0 ? ` (${selectedOptions.length})` : ''
            }`}
          </MenuButton>

          <MenuList
            maxHeight="300px"
            minW={'20vw'}
            overflowY="auto"
            backgroundColor={'white'}
          >
            <Box p={2} backgroundColor={'white'}>
              <Input
                placeholder="Search ..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                backgroundColor={'white'}
              />
            </Box>

            <MenuDivider />
            <MenuGroup title={undefined} backgroundColor={'white'}>
              <MenuItem
                backgroundColor={'white'}
                onClick={() => {
                  setSelectedOptions([]);
                  onChange([]);
                  onClose();
                }}
              >
                Clear all
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuOptionGroup
              backgroundColor={'white'}
              title={undefined}
              defaultValue={selectedOptions.map(
                option => option.id || option.name // Se não houver id, usamos name
              )}
              type="checkbox"
              onChange={values => {
                const updatedOptions = options.filter(option =>
                  values.includes(option.id || option.name)
                );
                setSelectedOptions(updatedOptions);
                onChange(updatedOptions);
              }}
            >
              {options
                .filter(option =>
                  option?.name
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
                .map(option => (
                  <MenuItemOption
                    backgroundColor={'white'}
                    key={`multiselect-menu-${option.id || option.name}`} // Usa id se disponível, caso contrário name
                    type="button"
                    _hover={{
                      bgColor: '#edf0f5',
                    }}
                    value={option.id || option.name} // Usa id ou name para validação
                  >
                    {option.name}
                  </MenuItemOption>
                ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;
