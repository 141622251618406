import React, { useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Radio,
  RadioGroup,
  Image,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const SendType = ({ sendDataToParent, nextStep }) => {
  const [type, setType] = useState('2');
  const { t } = useTranslation();

  const handleChange = async e => {
    setType(e);
    await sendDataToParent(e);
  };

  const handleStep = async e => {
    await nextStep();
  };

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: '120px', md: '25px' }}
        justifyContent="center"
        alignItems="center"
        minW={'60vw'}
      >
        <Text color={'black'} fontSize="2xl">
          {t('octUnitary.title')}
          <Divider borderColor={'black'} />
        </Text>

        <Text color={'#7e7477'} fontSize="md" mt={5}>
          {t('octUnitary.subTitle')}
          <Divider borderColor={'#7e7477'} />
        </Text>

        <Flex
          boxShadow="lg"
          borderRadius={10}
          p={10}
          mb={5}
          direction="row"
          pt={{ base: '120px', md: '25px' }}
        >
          <RadioGroup>
            <Flex direction="row">
              <Box
                cursor={'pointer'}
                borderRadius={8}
                width={{ base: '40vw', md: '15vw' }}
                minH={'15vh'}
                border={'1px solid #982549'}
                onClick={() => handleChange('1')}
                filter="grayscale(100%)"
                opacity={0.6}
                pointerEvents="none"
              >
                <Radio
                  p={2}
                  size="lg"
                  onClick={() => handleChange('1')}
                  isChecked={type === '1'}
                ></Radio>
                <Flex direction="column" align="center" justify="center">
                  <Image
                    p={4}
                    borderRadius={30}
                    bg={'#982549'}
                    cursor={'pointer'}
                    src="/img/credit_card.svg"
                  />
                  <Text as="b" color={'#3e3437'} pl={2} mt={2} fontSize="sm">
                    {t('octUnitary.sendCard')}
                  </Text>
                </Flex>
              </Box>

              <Box
                borderRadius={8}
                width={{ base: '40vw', md: '15vw' }}
                minH={'15vh'}
                border={'1px solid #982549'}
                mx={5}
                onClick={() => handleChange('2')}
                cursor={'pointer'}
              >
                <Radio
                  onClick={() => handleChange('2')}
                  isChecked={type === '2'}
                  p={2}
                  size="lg"
                ></Radio>
                <Flex direction="column" align="center" justify="center">
                  <Image
                    p={4}
                    borderRadius={30}
                    bg={'#982549'}
                    cursor={'pointer'}
                    src="/img/attach_money.svg"
                  />
                  <Text as="b" color={'#3e3437'} pl={2} mt={2} fontSize="sm">
                    {t('octUnitary.sendAccount')}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </RadioGroup>
        </Flex>

        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          py={5}
          borderRadius={10}
          mb={5}
        >
          <Button mr={4} onClick={handleStep} type="submit">
            <Text>{t('buttons.confirm')}</Text>
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
